<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'editLead'||modalId=='editProfile'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Edit Lead
            </h5>
            <a class="close" title="Close" @click="$store.dispatch('modalClose', 'newlead')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mt-0 mb-1">
                            <input
                              type="file"
                              id="lead_image"
                              style="display: none"
                              @change="base64Image"
                            />
                            <label
                              for="lead_image"
                              class="popup-img-container"
                            >
                              <span
                                >Browse <br />
                                Image</span
                              >
                              <div class="popup_img">
                                <img
                                  class="popup_img"
                                  :src="
                                    formData.img
                                      ? formData.img
                                      : '/assets/img/user_icon.png'
                                  "
                                  alt=""
                                />
                                <div id="change_img">
                                  Change <br />
                                  Image
                                </div>
                              </div>
                            </label>
                          </div>
                          <!-- <div class="col-md-7 pl-0 pd-t-30 pb-0">
                            <div class="row">
                              <div class="col-md-5 mr-0 pr-0">Lead ID</div>
                              <div class="col-md-7 ml-0 pl-0">
                                <input
                                  id="income_id"
                                  class="form-control"
                                  placeholder="id"
                                  v-model="formData.lead_id"
                                  disabled
                                />
                              </div>
                            </div>
                          </div> -->
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Lead Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              id="name"
                              class="form-control"
                              placeholder="Lead Name"
                              v-model="formData.name"
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Address</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              id="address"
                              class="form-control"
                              placeholder="Address"
                              v-model="formData.address"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Phone Number *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              id="phone_number"
                              class="form-control"
                              placeholder="Phone Number"
                              required
                              v-model="formData.phone_number"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Email</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              id="email"
                              class="form-control"
                              placeholder="abc@domain.com"
                              v-model="formData.email"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Generated Date</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              id="date"
                              class="form-control"
                              placeholder="YYYY/MM/DD"
                              v-model="formData.generate_date"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              type="text"
                              style="height: 2.85rem"
                              v-model="formData.description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Technical Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Branch *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              name="branch"
                              id="branch"
                              class="form-control"
                              v-model="formData.branch_id"
                              required
                            >
                              <option value="" disabled>Select Branch</option>
                              <option
                                v-for="(branch, index) in leadElements.filter_branchs"
                                :value="branch.id"
                                :key="index"
                              >
                                {{ branch.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Source *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              aria-placeholder="Employee Name"
                              v-model="formData.source"
                              required
                            >
                            <option value="" disabled>Select Source</option>
                              <option
                                v-for="(source, index) in leadElements.leadSource"
                                :key="index"
                                :value="source.id"
                              >
                                {{ source.title }}
                              </option>
                            </select>
                            <!-- <div class="input-group-prepend">
                              <div class="input-group-prepend">
                                <a
                                  href="javascript:;"
                                  class="btn-primary input-group-add-btn"
                                  @click="
                                    $store.dispatch('modalWithMode', {
                                      id: 'leadSource',
                                      mode: 'create',
                                    })
                                  "
                                  ><i class="fas fa-plus"></i
                                ></a>
                              </div>
                            </div> -->
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Status *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              v-model="formData.status"
                              required
                            >
                            <option value="" disabled>Select Status</option>
                              <option
                                v-for="(status, index) in leadElements.leadStatus"
                                :key="index"
                                :value="status.id"
                              >
                                {{ status.title }}
                              </option>
                            </select>
                            <!-- <div class="input-group-prepend">
                              <a
                                href="javascript:;"
                                class="btn-primary input-group-add-btn"
                                @click="
                                  $store.dispatch('modalWithMode', {
                                    id: 'leadStatus',
                                    mode: 'create',
                                  })
                                "
                                ><i class="fas fa-plus"></i
                              ></a>
                            </div> -->
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Priority *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              v-model="formData.priority"
                              required
                            >
                            <option value="" disabled>Select Priority</option>
                              <option
                                v-for="(priority,
                                index) in leadElements.leadPriority"
                                :key="index"
                                :value="priority.id"
                                required
                              >
                                {{ priority.title }}
                              </option>
                            </select>
                            <!-- <div class="input-group-prepend">
                              <div class="input-group-prepend">
                                <a
                                  href="javascript:;"
                                  class="btn-primary input-group-add-btn"
                                  @click="
                                    $store.dispatch('modalWithMode', {
                                      id: 'LeadPriority',
                                      mode: 'create',
                                    })
                                  "
                                  ><i class="fas fa-plus"></i
                                ></a>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Follow Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Assign to *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              name="Employee"
                              id="payment_method"
                              class="form-control"
                              v-model="formData.assigned_to"
                              required

                            >
                            <option value="" disabled>Assign To</option>
                              <option
                                v-for="(user, index) in leadElements.user"
                                :key="index"
                                :value="user.id"
                              >
                                {{ user.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Next Follow</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              name="paid_amount"
                              class="form-control"
                              placeholder=""
                              v-model="formData.next_follow_date"
                              required
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">To Do *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              v-model="formData.todo"
                              required
                            >
                              <option
                                v-for="(todo, index) in leadElements.leadTodo"
                                :key="index"
                                :value="todo.id"
                              >
                                {{ todo.title }}
                              </option>
                            </select>
                            <!-- <div class="input-group-prepend">
                              <div class="input-group-prepend">
                                <a
                                  href="javascript:;"
                                  class="btn-primary input-group-add-btn"
                                  @click="
                                    $store.dispatch('modalWithMode', {
                                      id: 'leadTodo',
                                      mode: 'create',
                                    })
                                  "
                                  ><i class="fas fa-plus"></i
                                ></a>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">
                  {{ disableSubmitButton ? 'Submitting....' : 'Submit'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <LeadProperty />
    <LeadSource />
    <LeadStatus />
    <LeadTodo />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import LeadProperty from "./Setting/leadPriority";
import LeadSource from "./Setting/leadSource";
import LeadStatus from "./Setting/leadStatus";
import LeadTodo from "./Setting/leadTodo";
import Services from "./Services/Services";
//import Service from '../Sales/Services/Service';
export default {
  data() {
    return {
      loading:true,
      error:false,
      disableSubmitButton:false,
      formData: {
        id: "",
        lead_id:"",
        email:"",
        phone_number:"",
        img: "",
        name: "",
        address: "",
        generate_date: "",
        branch_id: "",
        source: "",
        status: "",
        priority: "",
        assigned_to: "",
        next_follow_date: "",
        todo: "",
        description: "",
      },
    };
  },
  components: {
    LeadProperty,
    LeadSource,
    LeadStatus,
    LeadTodo,
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists1",
      "dataLists4",
      "dataLists6",
      "dataId",
      "modalId",
      "modalMode",
      "today",
      "modalClose",
    ]),
    ...mapGetters("leads",[
      "employees",
      "lead",
      "leadLists",
      "leadElements",
      "leadAllLists"
    ])
  },
  mounted() {
    //this.getData();
  },
  methods: {
    base64Image(e) {
      this.formData.img = null;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.formData.img = e.target.result;
      };
    },

    submit() {
     if (this.modalId == "editLead") {
       this.loading = true;
       this.disableSubmitButton = true;
        Services.updateLeadEditData(this.formData,this.formData.id).then(res=>{
              if(this.modalId == 'editProfile'){
                this.$emit('profile-edit');
              }else{
                this.$emit('parent-event');
              }
              this.$store.dispatch('modalClose');
              this.setNotification(res.data.success_message);
              this.loading = false;
              this.disableSubmitButton =false;
        }).catch(err=>{
            this.error=true;
            this.loading = false;
            this.disableSubmitButton = false;
            if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
             }
        });
      }
      if(this.modalId == 'editProfile'){
         this.disableSubmitButton = true;
        Services.updateEditProfile(this.formData,this.formData.id).then(res=>{
            this.$store.commit("leads/setLead",res.data.data);
            this.$store.dispatch('modalClose');
            this.setNotification(res.data.success_message);
             this.disableSubmitButton = false;
        }).catch(err=>{
          console.log(err);
           this.disableSubmitButton = false;
        });
      }
    },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
    getElements(){
      Services.getLeadElements().then(res=>{
         this.$store.commit("leads/setLeadElements",res.data.data);
          if(res.data.data.filter_branchs.length == 1){
            this.branchDisabled = true;
          }
          if(this.modalId == 'editLead'){
            let id = this.dataId;
            let details =  this.leadLists.find(function (data) {
                return data.id == id;
            })
            this.formData = {...details};
          }
          if(this.modalId == 'editProfile'){
            let details = this.lead;
            this.formData = {...details};
          }
      }).catch(err=>{
        console.log(err);
      });
    },
    clearData() {
      this.formData.id = "";
      this.formData.lead_id = "";
      this.formData.img = "";
      this.formData.name = "";
      this.formData.address = "";
      this.formData.generate_date = "";
      this.formData.branch_id = "";
      this.formData.source = "";
      this.formData.status = "";
      this.formData.priority = "";
      this.formData.assigned_to = "";
      this.formData.next_follow_date = "";
      this.formData.email="";
      this.formData.phone_number="";
      this.formData.todo = "";
      this.formData.description = "";
    },
  },
  watch: {
    modalId(value) {
      if (value == "editLead" || value=="editProfile") {
        this.clearData();
          if(this.modalMode == "edit"){
            this.getElements();
            
        }
      }
      
    },
  },
};
</script>